import React from 'react'

import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

// import useTheme from '@mui/material/styles/useTheme'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import { useNavigate } from 'react-router-dom'

const PopularQuestions = () => {
  // const theme = useTheme()
  const navigate = useNavigate()

  const questions = [
    'What does the Bible say about forgiveness?',
    'Can you find verses related to the concept of love in the New Testament?',
    'Where in the Bible does it discuss the creation of the world?',
    'How does the Bible address the issue of suffering?',
    'What are the Ten Commandments and where can they be found?',
    'What teachings does Jesus give about prayer?',
    'Where can I find the Beatitudes in the Bible?',
    'How is the concept of faith discussed in the letters of Paul?',
    'What are some prophetic books of the Old Testament?',
    'How does the Bible define the role of a prophet?',
    // 'If God is righteous, how can He pardon men who are wicked and still maintain His righteousness?',
    // 'Is God wrathful?',
    // 'Does God forgive anyone at all?',
    // 'Did Jesus go to the cross unwillingly?',
    // 'Can you have love without justice?',
    // 'Did God break the law for love?', // Steven Furtick
    // 'Can God change?',
    // 'What is hell like?'
  ]

  return (
    <Card sx={{ margin: 2 }}>
      <CardHeader title="Popular Questions" />
      <CardContent>
        <List>
          {questions.map((question, index) => (
            <ListItemButton
              key={index}
              onClick={() => {
                return navigate(`/search?q=${encodeURI(question)}`)
              }}
            >
              <ListItemText>{question}</ListItemText>
            </ListItemButton>
          ))}
        </List>
      </CardContent>
    </Card>
  )
}

export default PopularQuestions
