import React from 'react'

import AppAppBar from '../AppAppBar'

const StandardHeader = () => (
  <header>
    <AppAppBar />
  </header>
)

export default StandardHeader
