import React from 'react'
import { Outlet } from 'react-router-dom'

import { Helmet } from 'react-helmet-async'
import Container from '@mui/material/Container'
import useTheme from '@mui/material/styles/useTheme'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import PageMetadata from '../../components/PageMetadata'
import { HelmetData } from 'react-helmet-async'

const Homepage = () => {
  const theme = useTheme()
  const backgroundColorStyle = `body { background-color: ${theme.palette.background.default} }`

  const helmetData = new HelmetData({})

  return (
    <Container>
      <PageMetadata helmetData={helmetData} />
      <Helmet helmetData={helmetData}>
        <style>{backgroundColorStyle}</style>
      </Helmet>
      <Header />
      <Outlet />
      <Footer />
    </Container>
  )
}

export default Homepage
