import React from 'react'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import useTheme from '@mui/material/styles/useTheme'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useQuery } from '../../../lib/graphql-helpers'
import { requestWrapper } from '../../../lib/graphql-helpers'
import { gql } from '../../../lib/graphql-helpers'
import { getGraphQLErrorMessage } from '../../../lib/graphql-helpers'
import { request, useMutation } from '../../../lib/graphql-helpers'
import { useImmer } from 'use-immer'
import { useCallback } from 'react'
import { get } from 'lodash-es'
import { setJwtTokenCookie } from '../../../lib/authentication/cookie'

const VALIDATE_OTP_USER_TOKEN = gql`
  query validateOtpUserToken($token: String!) {
    validateOtpUserToken(token: $token) {
      userId
      email
      token
    }
  }
`

const CHECK_OTP_AND_LOGIN = gql`
  mutation checkOtp($token: String!, $otp: String!) {
    checkOtpAndLogin(oneTimePasswordUserToken: $token, otp: $otp) {
      loginTokenId
      jwtToken
    }
  }
`

const RESEND_LOGIN_USER_BY_EMAIL = gql`
  mutation resendLoginUserByEmail($email: String!, $source: String!) {
    loginUserByEmail(email: $email, source: $source) {
      userId
      token
    }
  }
`

const OneTimeCodeLogin = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const queryParams = useQueryParams()
  let oneTimePasswordUserToken = null
  if (queryParams.get('t') !== null && queryParams.get('t') !== '') {
    oneTimePasswordUserToken = queryParams.get('t')
  }

  const {
    data: dataValidateOtpUserToken,
    isSuccess: isSuccessValidateOtpUserToken,
    error,
    isError,
  } = useQuery({
    queryKey: ['validateOtpUserToken', { token: oneTimePasswordUserToken }],
    queryFn: requestWrapper(VALIDATE_OTP_USER_TOKEN, {
      token: oneTimePasswordUserToken,
    }),
  })

  useEffect(() => {
    if (isSuccessValidateOtpUserToken) {
      const successEmail = get(dataValidateOtpUserToken, 'validateOtpUserToken.email')
      setEmail(successEmail)
    }
    if (isError) {
      const message = getGraphQLErrorMessage(error)
      if (message === 'INVALID_TOKEN') {
        navigate('/signin')
      }
    }
  }, [error, isError, isSuccessValidateOtpUserToken])

  const [email, setEmail] = useImmer(null)

  const {
    mutate: mutateResendLoginByEmail,
    isLoading: isLoadingResendLoginByEmail,
    isPending: isPendingResendLoginByEmail,
    isError: isErrorResendLoginByEmail,
    data: dataResendLoginByEmail,
    error: errorResendLoginByEmail,
    isSuccess: isSuccessResendLoginByEmail,
  } = useMutation({
    mutationFn: () => {
      const endpoint = `${process.env.REACT_APP_GRAPHQL_SERVER_ROOT_URL}/graphql`
      return request(endpoint, RESEND_LOGIN_USER_BY_EMAIL, {
        email,
        source: 'signin-web-resend',
      })
    },
  })

  const [generalFormErrorMessage, setGeneralFormErrorMessage] = useImmer(null)

  const [formValues, setFormValues] = useImmer({
    otp: '',
  })

  const [formErrorMessages, setFormErrorMessages] = useImmer({
    otp: null,
  })

  useEffect(() => {
    if (!oneTimePasswordUserToken || oneTimePasswordUserToken === 'undefined' || oneTimePasswordUserToken === 'null') {
      navigate('/signin')
    }
  }, [oneTimePasswordUserToken])

  const {
    mutate: mutationCheckOtpAndLogin,
    isSuccess: otpIsSuccess,
    data: otpData,
    error: otpError,
    isError: otpIsError,
  } = useMutation({
    mutationFn: () => {
      const endpoint = `${process.env.REACT_APP_GRAPHQL_SERVER_ROOT_URL}/graphql`
      return request(endpoint, CHECK_OTP_AND_LOGIN, {
        token: oneTimePasswordUserToken,
        otp: formValues.otp,
      })
    },
  })

  useEffect(() => {
    if (otpIsSuccess) {
      const token = get(otpData, 'checkOtpAndLogin.jwtToken')
      // console.log('User logged in successfully', otpData)
      // drop a JWT cookie for the current user
      setJwtTokenCookie(token)
      navigate('/dashboard')
    }
    if (otpIsError) {
      const message = getGraphQLErrorMessage(otpError)
      if (message === 'INVALID_TOKEN') {
        // console.log('TODO message to try again to send another token')
        setGeneralFormErrorMessage('Invalid one-time verification code, please use the "Send another code" button to try again')
      }
    }
  }, [otpIsSuccess, otpIsError, otpError, otpData])

  const onSendCodeAgainButtonPressed = (evt) => {
    // console.log('Send code again button pressed')
    mutateResendLoginByEmail()
    setFormValues((draft) => {
      draft['otp'] = ''
    })
    setFormErrorMessages((draft) => {
      draft['otp'] = null
    })
    setGeneralFormErrorMessage(null)
  }

  const isFieldValid = (fieldName) => {
    return formErrorMessages[fieldName] !== null
  }

  const isFormValid = () => {
    const keys = Object.keys(formErrorMessages)
    // loop over all keys, check all null
    for (const key of keys) {
      if (formErrorMessages[key] !== null) {
        return false
      }
      if (formValues[key] === '') {
        return false
      }
    }
    return !generalFormErrorMessage
  }

  const handleValueChange = (evt) => {
    const { name, value } = evt.target
    setFormValues((draft) => {
      draft[name] = value
    })
  }

  const onValidateField = (evt) => {
    const { name, value } = evt.target
    if (name === 'otp') {
      if (value.length < 6) {
        setFormErrorMessages((draft) => {
          draft[name] = 'One-time code is six numbers'
        })
      } else {
        setFormErrorMessages((draft) => {
          draft[name] = null
        })
      }
    }
  }

  const handleSubmit = useCallback(
    (evt) => {
      evt.preventDefault()
      setGeneralFormErrorMessage(null)
      mutationCheckOtpAndLogin()
    },
    [formValues]
  )

  return (
    <Container
      sx={{
        padding: theme.spacing(4, 0, 2, 2),
      }}
    >
      <Paper elevation={2} square={false}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            padding: theme.spacing(4),
          }}
        >
          {email && <Typography>Enter the one-time code sent to your email: {email}</Typography>}
          {!email && <Typography>Enter the one-time code sent to your email</Typography>}

          {/* Show the 6 number field input */}
          <TextField
            type="text"
            placeholder="Enter 6 digit one-time password"
            variant="outlined"
            inputProps={{ maxLength: 6 }}
            autoFocus
            name="otp"
            onChange={(evt) => {
              handleValueChange(evt)
              onValidateField(evt)
            }}
            error={isFieldValid('otp')}
            helperText={formErrorMessages['otp']}
            value={formValues.otp}
            sx={{
              width: '75%',
            }}
          />

          {generalFormErrorMessage && (
            <Typography variant="body2" color="error">
              {generalFormErrorMessage}
            </Typography>
          )}

          <div>
            <Button
              type="submit"
              variant="contained"
              disabled={!isFormValid()}
              sx={{
                margin: 2,
              }}
            >
              Verify
            </Button>

            <Button
              variant="contained"
              color="secondary"
              onClick={(evt) => onSendCodeAgainButtonPressed(evt)}
              sx={{
                margin: 2,
              }}
            >
              Send another code
            </Button>
          </div>
        </Box>
      </Paper>
    </Container>
  )
}

export default OneTimeCodeLogin
