import { useMemo } from 'react'
import short from 'short-uuid'

export const useUuid = () => {
  return useMemo(() => {
    const translator = short()
    const suuid = translator.generate()
    return suuid
  }, [])
}
