import React, { useState, useEffect } from 'react'
import { gql, useLazyQuery } from '@apollo/client'

import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

const QUERY_NEAREST_CHUNKS = gql`
  query queryNearestSermonChunks($query: String!) {
    queryNearestSermonChunks(query: $query) {
      text
      start
      end
      youtube_url
      pointer_in_seconds
      channel_id
      video_id
      hash
      max_num_words
    }
  }
`

const Homepage = () => {
  const [query, setQuery] = useState('love')

  const [doSearchQuery, { data: chunksData, loading: chunksLoading }] = useLazyQuery(QUERY_NEAREST_CHUNKS)

  useEffect(() => {
    doSearchQuery({
      variables: {
        query,
      },
    })
  }, [])

  const onSearchInputChange = (event) => {
    const qry = event.target.value
    setQuery(qry)
  }
  const doQuery = async () => {
    doSearchQuery({
      variables: {
        query,
      },
    })
  }

  // make input work on key press enter
  const handleSubmitOnKeyDown = (event) => {
    console.log('event', event.key)
    if (event.key === 'Enter') {
      doSearchQuery({
        variables: {
          query,
        },
      })
    }
  }

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1>Sermon Video Search</h1>
        </Grid>

        <Grid item xs={12}>
          <TextField type="text" placeholder="" value={query} onChange={onSearchInputChange} onKeyDown={handleSubmitOnKeyDown} />
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" onClick={() => doQuery()}>
            Search
          </Button>
        </Grid>

        <Grid item xs={12}>
          {chunksLoading && <p>Loading...</p>}
        </Grid>

        {chunksData && !chunksLoading && (
          <ul>
            {chunksData.queryNearestSermonChunks.map((chunk) => (
              <li key={chunk.text}>
                <div>{chunk.text}</div>
                <div>{chunk.youtube_url}</div>
                <div>{chunk.start}</div>
                <div>{chunk.end}</div>
                <div>{chunk.channel_id}</div>
                <div>{chunk.video_id}</div>
                <div>{chunk.hash}</div>
                <div>{chunk.max_num_words}</div>
              </li>
            ))}
          </ul>
        )}
      </Grid>
    </Container>
  )
}

export default Homepage
