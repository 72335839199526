import React from 'react'

import BibleSearchInput from '../BibleSearchInput'
import PopularQuestions from '../PopularQuestions'

const MainHomepageContent = () => {
  return (
    <>
      <BibleSearchInput />
      <PopularQuestions />
    </>
  )
}

export default MainHomepageContent
