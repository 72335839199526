import React from 'react'
import { Outlet } from 'react-router-dom'
import { Helmet, HelmetData } from 'react-helmet-async'

import useTheme from '@mui/material/styles/useTheme'
import Container from '@mui/material/Container'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

import PageMetadata from '../../components/PageMetadata'

const PublicPageDefault = () => {
  const theme = useTheme()
  const backgroundColorStyle = `body { background-color: ${theme.palette.background.default} }`

  const helmetData = new HelmetData({})

  return (
    <Container>
      <PageMetadata helmetData={helmetData} />
      <Helmet prioritizeSeoTags helmetData={helmetData}>
        <style>{backgroundColorStyle}</style>
      </Helmet>
      <Header />
      <Outlet />
      <Footer />
    </Container>
  )
}

export default PublicPageDefault
