import React, { useState, useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'

import useTheme from '@mui/material/styles/useTheme'

import { Helmet } from 'react-helmet-async'
import { setJwtTokenCookie } from '../../../lib/authentication/cookie'

import { gql, useMutation } from '../../../lib/graphql-helpers'
import { mutationWrapper } from '../../../lib/graphql-helpers'
import { useCurrentUser } from '../../../providers/CurrentUserProvider'

const SAVE_GOOGLE_AUTH_CODE_AND_LOGIN = gql`
  mutation saveGoogleAuthCodeAndLogin($authCode: String!) {
    saveGoogleAuthCodeAndLogin(authCode: $authCode) {
      loginTokenId
      jwtToken
    }
  }
`

const AuthGoogleCallback = () => {
  const [urlParams] = useSearchParams()
  const navigate = useNavigate()

  const { setIsCurrentUserLoaded } = useCurrentUser()

  const theme = useTheme()

  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const backgroundColorStyle = `body { background-color: ${theme.palette.background.default} }`

  const {
    mutate: saveGoogleAuthCodeAndLogin,
    isLoading: saveAuthCodeLoading,
    isPending,
    isError,
    data: saveAuthCodeData,
    error: saveAuthCodeError,
    isSuccess,
  } = useMutation({
    mutationFn: mutationWrapper(SAVE_GOOGLE_AUTH_CODE_AND_LOGIN),
  })

  useEffect(() => {
    if (!isPending && isError) {
      if (saveAuthCodeError) {
        // redirect to login page with error message
        navigate('/signin?error=google-auth-failed')
      }
    }
  }, [isPending, saveAuthCodeError, isError])

  useEffect(() => {
    if (isLoggedIn) {
      setIsCurrentUserLoaded(false)
      navigate('/dashboard')
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (!saveAuthCodeLoading && saveAuthCodeData) {
      const { saveGoogleAuthCodeAndLogin: result } = saveAuthCodeData
      const { jwtToken } = result
      // drop a JWT cookie for the current user
      setJwtTokenCookie(jwtToken)
      setIsLoggedIn(true)
    }
  }, [saveAuthCodeData, saveAuthCodeLoading])

  // at this point, we get a URL param from the google callback
  useEffect(() => {
    async function main() {
      const code = urlParams.get('code')
      if (code) {
        // make request to graphql to store all the details
        saveGoogleAuthCodeAndLogin({ authCode: code })
      } else {
        // redirect to login if not code param is present
        navigate('/signin')
      }
    }

    main()
  }, [navigate, saveGoogleAuthCodeAndLogin, urlParams])

  return (
    <>
      <Helmet>
        <style>{backgroundColorStyle}</style>
      </Helmet>
      <div>{/* <p>Please wait while we process your request...</p> */}</div>
    </>
  )
}
export default AuthGoogleCallback
