import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'

import { gql, useQuery, requestWrapper } from '../lib/graphql-helpers'

export const CurrentUserContext = React.createContext()

const GET_CURRENT_USER = gql`
  query getCurrentUser {
    getCurrentUser {
      userId
      email
      fullName
    }
  }
`

const CurrentUserProvider = (props) => {
  const { children } = props

  const [currentUser, setCurrentUser] = useState(null)
  const [isCurrentUserLoaded, setIsCurrentUserLoaded] = useState(false)

  const {
    isFetching,
    data,
    isSuccess,
    isError,
    refetch: refetchCurrentUser,
  } = useQuery({
    queryKey: ['currentUser'],
    queryFn: requestWrapper(GET_CURRENT_USER),
  })

  useEffect(() => {
    if (isSuccess && !isFetching) {
      const output = get(data, 'getCurrentUser')
      if (output) {
        setCurrentUser(output)
      }
      setIsCurrentUserLoaded(true)
    }
  }, [isSuccess, isFetching, data])

  useEffect(() => {
    if (isError && !isFetching) {
      setCurrentUser(false)
      setIsCurrentUserLoaded(true)
    }
  }, [isError, isFetching])

  const contextValue = useMemo(
    () =>
      // console.log('CurrentUserProvider > useMemo > contextValue > currentUser:', currentUser)
      ({ currentUser, isCurrentUserLoaded, refetchCurrentUser, setIsCurrentUserLoaded }),
    [currentUser, isCurrentUserLoaded, refetchCurrentUser, setIsCurrentUserLoaded]
  )

  return <CurrentUserContext.Provider value={contextValue}>{children}</CurrentUserContext.Provider>
}

CurrentUserProvider.propTypes = {
  children: PropTypes.node,
}

CurrentUserProvider.defaultProps = {
  children: null,
}

export default CurrentUserProvider

export const useCurrentUser = () => React.useContext(CurrentUserContext)
