import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { CheckCircle, Cancel } from '@mui/icons-material'

const IconWrapper = styled('span')(({ value }) => ({
  display: 'inline-block',
  color: value ? 'green' : 'red',
}))

const StatusIcon = ({ value, size }) => {
  const Icon = value ? CheckCircle : Cancel
  return (
    <IconWrapper value={value}>
      <Icon style={{ width: size, height: size }} />
    </IconWrapper>
  )
}

StatusIcon.propTypes = {
  value: PropTypes.bool.isRequired,
  size: PropTypes.string,
}

StatusIcon.defaultProps = {
  size: '1em',
}

export default StatusIcon
