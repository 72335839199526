import React, { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'

import { useMutation, gql } from '@apollo/client'

import { setJwtTokenCookie } from '../../../lib/authentication/cookie'

const SAVE_SPOTIFY_AUTH_CODE_AND_LOGIN = gql`
  mutation saveSpotifyAuthCodeAndLogin($authCode: String!) {
    saveSpotifyAuthCodeAndLogin(authCode: $authCode) {
      loginTokenId
      jwtToken
    }
  }
`

const AuthSpotifyCallback = () => {
  const [urlParams] = useSearchParams()
  const navigate = useNavigate()

  const [saveSpotifyAuthCodeAndLogin, { data: saveAuthCodeData, loading: saveAuthCodeLoading, error: saveAuthCodeError }] =
    useMutation(SAVE_SPOTIFY_AUTH_CODE_AND_LOGIN)

  useEffect(() => {
    if (saveAuthCodeError) {
      // redirect to login page with error message
      navigate('/login?error=spotify-auth-failed')
    }
  }, [saveAuthCodeError])

  useEffect(() => {
    if (!saveAuthCodeLoading && saveAuthCodeData) {
      const { saveSpotifyAuthCodeAndLogin: result } = saveAuthCodeData
      const { jwtToken } = result
      // drop a JWT cookie for the current user
      setJwtTokenCookie(jwtToken)
      navigate('/dashboard')
    }
  }, [saveAuthCodeData, saveAuthCodeLoading])

  // at this point, we get a URL param from spotify
  useEffect(() => {
    async function main() {
      const code = urlParams.get('code')
      if (code) {
        // make request to graphql to store all the details
        await saveSpotifyAuthCodeAndLogin({ variables: { authCode: code } })
      } else {
        // redirect to login if not code param is present
        navigate('/login')
      }
    }

    main()
  }, [navigate, saveSpotifyAuthCodeAndLogin, urlParams])

  return (
    <div>
      <p>Please wait while we process your request...</p>
    </div>
  )
}
export default AuthSpotifyCallback
