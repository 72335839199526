import React from 'react'
import useTheme from '@mui/material/styles/useTheme'
import { deleteJwtTokenCookie } from '../../../lib/authentication/cookie'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'

const Signout = () => {
  const theme = useTheme()
  const backgroundColorStyle = `body { background-color: ${theme.palette.background.default} }`

  // remove the token from local storage or cookie
  useEffect(() => {
    deleteJwtTokenCookie()
  }, [])

  return (
    <Container
      component="main"
      sx={{
        margin: theme.spacing(2, 0, 2, 0),
      }}
    >
      <Helmet>
        <style>{backgroundColorStyle}</style>
      </Helmet>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: theme.spacing(2),
        }}
      >
        <Typography variant="h5" paddingTop={4} paddingBottom={2}>
          You have been signed out
        </Typography>
      </Paper>
    </Container>
  )
}

export default Signout
