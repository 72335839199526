import Cookies from 'js-cookie'

let COOKIE_SECURE = false
if (typeof window !== 'undefined') {
  // if localhost, don't set secure cookies (for dev)
  COOKIE_SECURE = window.location.hostname.indexOf('localhost') < 0
}
const COOKIE_PATH = '/'
const COOKIE_EXPIRES = 1000 // days

const JWT_AUTH_TOKEN_NAME = 'jwt'

export const deleteJwtTokenCookie = () => {
  const oldJwt = Cookies.remove(JWT_AUTH_TOKEN_NAME, { path: COOKIE_PATH })
  return oldJwt
}

export const setJwtTokenCookie = (token) => {
  if (token) {
    deleteJwtTokenCookie()
    return Cookies.set(JWT_AUTH_TOKEN_NAME, token, { expires: COOKIE_EXPIRES, path: COOKIE_PATH, secure: COOKIE_SECURE })
  }
  return null
}

export const getJwtTokenFromCookie = () => {
  const token = Cookies.get(JWT_AUTH_TOKEN_NAME)
  if (!token) {
    return null
  }
  return token
}
