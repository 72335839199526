import { gql, request } from 'graphql-request'
import { useQuery, useMutation } from '@tanstack/react-query'
import { getJwtTokenFromCookie } from '../authentication/cookie'

export { useQuery, useMutation, gql, request }

export const requestWrapper =
  (query, variables = null, additionalHeaders = {}) =>
  async () => {
    const graphqlUrl = `${process.env.REACT_APP_GRAPHQL_SERVER_ROOT_URL}/graphql`

    // look for jwt token in local storage and add it to the headers
    const token = getJwtTokenFromCookie()

    let config = {
      url: graphqlUrl,
      document: query,
      requestHeaders: {
        'X-Sent-At-Time': Date.now().toString(),
        authorization: token ? `Bearer ${token}` : '',
      },
    }

    if (variables) {
      config.variables = variables
    }

    if (additionalHeaders) {
      config.requestHeaders = { ...config.requestHeaders, ...additionalHeaders }
    }

    return request(config)
  }

export const mutationWrapper = (mutation) => async (variables) => {
  const graphqlUrl = `${process.env.REACT_APP_GRAPHQL_SERVER_ROOT_URL}/graphql`

  // look for jwt token in local storage and add it to the headers
  const token = getJwtTokenFromCookie()

  let config = {
    url: graphqlUrl,
    document: mutation,
    requestHeaders: {
      'X-Sent-At-Time': Date.now().toString(),
      authorization: token ? `Bearer ${token}` : '',
    },
    variables,
  }

  return request(config)
}

export const getGraphQLErrorMessage = (error) => {
  if (error && error.response && error.response.errors) {
    return error.response.errors[0].message
  }
  return 'DEFAULT_ERROR_MESSAGE'
}
