import React from 'react'
import PropTypes from 'prop-types'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'

import ShareOnSocial from 'react-share-on-social'
import ShareIcon from '@mui/icons-material/Share'

import favicon from '../../assets/favicon.png'

const WebShareCard = ({ url, title, description }) => {
  return (
    <Card style={{ marginTop: '1em', marginBottom: '1em' }}>
      <CardContent style={{ paddingBottom: '16px' }}>
        <ShareOnSocial
          linkFavicon={favicon}
          link={url}
          shareTo={['email', 'facebook', 'twitter', 'reddit', 'whatsapp', 'telegram']}
          linkTitle={title}
          textToShare={description}
        >
          <Button color="secondary" variant="contained" startIcon={<ShareIcon />} title={'Share on social media'}>
            Share the Word
          </Button>
        </ShareOnSocial>
      </CardContent>
    </Card>
  )
}

WebShareCard.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
}

WebShareCard.defaultProps = {
  url: window.location.href,
  title: null,
  description: null,
}

export default WebShareCard
