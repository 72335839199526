import React from 'react'

import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import useTheme from '@mui/material/styles/useTheme'
import { Helmet } from 'react-helmet-async'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useImmer } from 'use-immer'
import { useCallback } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useMutation } from '../../lib/graphql-helpers'
import { mutationWrapper } from '../../lib/graphql-helpers'
import { gql } from '../../lib/graphql-helpers'

const SUBMIT_CONTACT_FORM = gql`
  mutation submitContactForm($name: String!, $email: String!, $message: String!, $captcha: String!) {
    submitContactForm(name: $name, email: $email, message: $message, captcha: $captcha) {
      submitted
    }
  }
`

const FormContainer = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '400px',
  margin: '0 auto',
}))

const SubmitButton = styled(Button)(() => ({
  marginTop: '1rem',
}))

const ContactForm = () => {
  const theme = useTheme()
  const backgroundColorStyle = `body { background-color: ${theme.palette.background.default} }`

  const { mutate, data, error, isSuccess } = useMutation({
    mutationFn: mutationWrapper(SUBMIT_CONTACT_FORM),
  })

  const [formValues, setFormValues] = useImmer({
    name: '',
    email: '',
    message: '',
    captcha: '',
  })

  const [formErrorMessages, setFormErrorMessages] = useImmer({
    name: null,
    email: null,
    message: null,
    captcha: null,
  })

  const [generalFormErrorMessage, setGeneralFormErrorMessage] = useImmer(null)

  const isFieldValid = (fieldName) => {
    return formErrorMessages[fieldName] !== null
  }

  const isFormValid = () => {
    const keys = Object.keys(formErrorMessages)
    // loop over all keys, check all null
    for (const key of keys) {
      if (formErrorMessages[key] !== null) {
        return false
      }
      if (formValues[key] === '') {
        return false
      }
    }
    return !generalFormErrorMessage
  }

  const handleValueChange = (evt) => {
    const { name, value } = evt.target
    setFormValues((draft) => {
      draft[name] = value
    })
    setGeneralFormErrorMessage(null)
  }

  const handleCaptchaValueChange = useCallback((value) => {
    setFormValues((draft) => {
      draft['captcha'] = value
    })
    setGeneralFormErrorMessage(null)
  }, [])

  const onValidateField = (evt) => {
    const { name, value } = evt.target
    if (name === 'name') {
      if (value.length < 1) {
        setFormErrorMessages((draft) => {
          draft[name] = 'Name is required'
        })
      } else {
        setFormErrorMessages((draft) => {
          draft[name] = null
        })
      }
    }
    if (name === 'email') {
      if (value.length < 1) {
        setFormErrorMessages((draft) => {
          draft[name] = 'Email is required'
        })
      } else if (!value.includes('@')) {
        setFormErrorMessages((draft) => {
          draft[name] = 'Email is not valid'
        })
      } else {
        setFormErrorMessages((draft) => {
          draft[name] = null
        })
      }
    }
    if (name === 'message') {
      if (value.length < 1) {
        setFormErrorMessages((draft) => {
          draft[name] = 'Message is required'
        })
      } else {
        setFormErrorMessages((draft) => {
          draft[name] = null
        })
      }
    }
  }

  const onValidateCaptchaField = (value) => {
    if (value.length < 1) {
      setFormErrorMessages((draft) => {
        draft['captcha'] = 'Verify you are not a robot.'
      })
    } else {
      setFormErrorMessages((draft) => {
        draft[name] = null
      })
    }
  }

  const handleSubmit = useCallback(
    (evt) => {
      evt.preventDefault()
      setGeneralFormErrorMessage(null)
      mutate({
        name: formValues.name,
        email: formValues.email,
        message: formValues.message,
        captcha: formValues.captcha,
      })
    },
    [formValues]
  )

  return (
    <Container
      component="main"
      sx={{
        margin: theme.spacing(2, 0, 2, 0),
      }}
    >
      <Helmet>
        <title>Contact Us</title>
        <style>{backgroundColorStyle}</style>
      </Helmet>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          paddingBottom: theme.spacing(2),
        }}
      >
        {isSuccess ? (
          <Typography variant="h4" padding={theme.spacing(4, 2, 2, 4)}>
            Thank you for contacting us!
          </Typography>
        ) : (
          <>
            <Typography variant="h4" padding={theme.spacing(2, 2, 2, 4)}>
              Contact Us
            </Typography>
            <FormContainer
              onSubmit={handleSubmit}
              sx={{
                mt: 1,
                margin: theme.spacing(0, 4),
              }}
            >
              <TextField
                id="name"
                label="Name"
                variant="outlined"
                name="name"
                required
                onChange={(evt) => {
                  handleValueChange(evt)
                  onValidateField(evt)
                }}
                error={isFieldValid('email')}
                helperText={formErrorMessages['email']}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                autoFocus
                name="email"
                autoComplete="email"
                onChange={(evt) => {
                  handleValueChange(evt)
                  onValidateField(evt)
                }}
                error={isFieldValid('email')}
                helperText={formErrorMessages['email']}
              />
              <TextField
                label="Message"
                variant="outlined"
                name="message"
                multiline
                rows={4}
                required
                onChange={(evt) => {
                  handleValueChange(evt)
                  onValidateField(evt)
                }}
                error={isFieldValid('email')}
                helperText={formErrorMessages['email']}
              />
              <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                <ReCAPTCHA
                  sitekey="6Lchf_gpAAAAAPAvzJhIls5b3w7MTDgJEe2vE7fx"
                  onChange={(val) => {
                    handleCaptchaValueChange(val)
                    onValidateCaptchaField(val)
                  }}
                />
              </Box>
              <SubmitButton disabled={!isFormValid()} fullWidth variant="contained" type="submit" sx={{ mt: 3, mb: 2 }}>
                Submit
              </SubmitButton>
            </FormContainer>
          </>
        )}
      </Paper>
    </Container>
  )
}

export default ContactForm
