import React from 'react'

import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom'

import App from './components/App'
import AuthFacebookCallback from './components/Auth/AuthFacebookCallback'
import AuthGoogleCallback from './components/Auth/AuthGoogleCallback'
import AuthSpotifyCallback from './components/Auth/AuthSpotifyCallback'

import Dashboard from './pages/Dashboard'

import Login from './components/Auth/Login'
import Signup from './components/Auth/Signup'
import Signout from './components/Auth/Signout'
import OneTimeCodeLogin from './components/Auth/OneTimeCodeLogin'

import NotFound from './components/NotFound'
import PrivateRoute from './components/PrivateRoute'
import Privacy from './components/Privacy'
import Contact from './components/Contact'
import Terms from './components/TermsOfService'

import PublicPageDefault from './pages/PublicPageDefault'

import Homepage from './pages/Homepage'
import MainHomepageContent from './pages/Homepage/MainHomepageContent'
import SermonSearch from './pages/Homepage/SermonSearch'
import StatementOfFaith from './pages/StatementOfFaith'

import SearchResults from './pages/SearchResults'
import PrivatePageDefault from './pages/PrivatePageDefault'

import QuestionVerseDetail from './pages/Bible/QuestionVerseDetail'

const routes = createRoutesFromElements(
  <Route path="/" element={<App />}>
    <Route path="/" element={<Homepage />}>
      <Route path="" element={<MainHomepageContent />} />
      <Route path="/sermon" element={<SermonSearch />} />
    </Route>
    <Route path="/search" element={<PublicPageDefault />}>
      <Route path="" element={<SearchResults />} />
    </Route>
    <Route path="/bible" element={<PublicPageDefault />}>
      <Route path="match/:questionSlug/:verseSlug" element={<QuestionVerseDetail />} />
    </Route>
    <Route path="/faith" element={<PublicPageDefault />}>
      <Route path="" element={<StatementOfFaith />} />
    </Route>
    <Route path="/auth/facebook/callback" element={<AuthFacebookCallback />} />
    <Route path="/auth/spotify/callback" element={<AuthSpotifyCallback />} />
    <Route path="/auth/google/callback" element={<AuthGoogleCallback />} />
    <Route path="/signin" element={<PublicPageDefault />}>
      <Route path="" element={<Login />} />
    </Route>
    <Route path="/signup" element={<PublicPageDefault />}>
      <Route path="" element={<Signup />} />
    </Route>
    <Route path="/signout" element={<PublicPageDefault />}>
      <Route path="" element={<Signout />} />
    </Route>
    <Route path="/otp" element={<PublicPageDefault />}>
      <Route path="" element={<OneTimeCodeLogin />} />
    </Route>
    <Route path="/privacy" element={<PublicPageDefault />}>
      <Route path="" element={<Privacy />} />
    </Route>
    <Route path="/terms" element={<PublicPageDefault />}>
      <Route path="" element={<Terms />} />
    </Route>
    <Route path="/contact" element={<PublicPageDefault />}>
      <Route path="" element={<Contact />} />
    </Route>
    <Route path="*" element={<PublicPageDefault />}>
      <Route path="*" element={<NotFound />} />
    </Route>
    <Route element={<PrivateRoute />}>
      <Route path="/dashboard" element={<PrivatePageDefault />}>
        <Route path="" element={<Dashboard />} />
      </Route>
    </Route>
  </Route>
)

const router = createBrowserRouter(routes)

export default router
