import React, { useCallback } from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { Helmet } from 'react-helmet-async'
import Paper from '@mui/material/Paper'
import useTheme from '@mui/material/styles/useTheme'
import logoImage from '../../../assets/gospelgarden-logo-horizontal.png'
import { styled } from '@mui/material/styles'
import { useImmer } from 'use-immer'

import { gql, useQuery, useMutation, mutationWrapper, request, getGraphQLErrorMessage } from '../../../lib/graphql-helpers'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { createSearchParams } from 'react-router-dom'
import { get } from 'lodash-es'
import GoogleAuthButton from '../GoogleAuthButton'

const MUTATION_REGISTER_USER = gql`
  mutation registerUserByEmail($firstName: String!, $lastName: String!, $email: String!, $source: String!) {
    registerUserByEmail(firstName: $firstName, lastName: $lastName, email: $email, source: $source) {
      userId
      token
    }
  }
`

const LogoContainer = styled('div', { name: 'LogoContainer' })(({ theme }) => ({
  margin: theme.spacing(1, 1, 3, 1),
}))

const Signup = () => {
  const theme = useTheme()
  const backgroundColorStyle = `body { background-color: ${theme.palette.background.default} }`

  const navigate = useNavigate()

  const [generalFormErrorMessage, setGeneralFormErrorMessage] = useImmer(null)

  const [formValues, setFormValues] = useImmer({
    firstName: '',
    lastName: '',
    email: '',
    allowExtraEmails: true,
  })

  const [formErrorMessages, setFormErrorMessages] = useImmer({
    firstName: null,
    lastName: null,
    email: null,
  })

  const { mutate, isError, isSuccess, isPending, error, data } = useMutation({
    mutationFn: () => {
      const endpoint = `${process.env.REACT_APP_GRAPHQL_SERVER_ROOT_URL}/graphql`
      return request(endpoint, MUTATION_REGISTER_USER, {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: formValues.email,
        source: 'signup-web',
      })
    },
  })

  useEffect(() => {
    if (isSuccess) {
      // console.log('User registered successfully', data)
      const token = get(data, 'registerUserByEmail.token')
      navigate({
        pathname: '/otp',
        search: createSearchParams({
          t: token,
        }).toString(),
      })
    }
    if (isError) {
      const message = getGraphQLErrorMessage(error)
      if (message === 'USER_EXISTS') {
        // make this HTML and link to sign in page
        const errorMessage = 'Did you already signup? Please try to sign in instead.'
        setGeneralFormErrorMessage(errorMessage)
      }
    }
  }, [isSuccess, isError, isPending, error, data])

  const isFieldValid = (fieldName) => {
    return formErrorMessages[fieldName] !== null
  }

  const isFormValid = () => {
    const keys = Object.keys(formErrorMessages)
    // loop over all keys, check all null
    for (const key of keys) {
      if (formErrorMessages[key] !== null) {
        return false
      }
      if (formValues[key] === '') {
        return false
      }
    }
    return !generalFormErrorMessage
  }

  const handleValueChange = (evt) => {
    const { name, value } = evt.target
    setFormValues((draft) => {
      draft[name] = value
    })
  }

  const handleCheckboxChange = (evt) => {
    const { name, checked } = evt.target
    setFormValues((draft) => {
      draft[name] = checked
    })
  }

  const onValidateField = (evt) => {
    const { name, value } = evt.target
    if (name === 'firstName') {
      if (value.length < 1) {
        setFormErrorMessages((draft) => {
          draft[name] = 'First name is required'
        })
      } else {
        setFormErrorMessages((draft) => {
          draft[name] = null
        })
      }
    }
    if (name === 'lastName') {
      if (value.length < 1) {
        setFormErrorMessages((draft) => {
          draft[name] = 'Last name is required'
        })
      } else {
        setFormErrorMessages((draft) => {
          draft[name] = null
        })
      }
    }
    if (name === 'email') {
      if (value.length < 1) {
        setFormErrorMessages((draft) => {
          draft[name] = 'Email is required'
        })
      } else if (!value.includes('@')) {
        setFormErrorMessages((draft) => {
          draft[name] = 'Email is not valid'
        })
      } else {
        setFormErrorMessages((draft) => {
          draft[name] = null
        })
      }
    }
  }

  const handleSubmit = useCallback(
    (evt) => {
      evt.preventDefault()
      setGeneralFormErrorMessage(null)
      mutate()
    },
    [formValues]
  )

  return (
    <Container
      component="main"
      sx={{
        margin: theme.spacing(2, 0, 2, 0),
      }}
    >
      <Helmet>
        <style>{backgroundColorStyle}</style>
      </Helmet>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: theme.spacing(2),
        }}
      >
        <LogoContainer>
          <img src={logoImage} width="300em" alt="logo" />
        </LogoContainer>

        <Typography component="h1" variant="h4" sx={{ marginBottom: theme.spacing(5) }}>
          Sign up
        </Typography>

        {/* ----------- START: Google and Facebook login buttons ------------- */}
        <Box
          sx={{
            mt: 1,
            margin: theme.spacing(0, 4, 4, 4),
          }}
        >
          <GoogleAuthButton buttonLabel="Sign up with Google" />
        </Box>

        {/* responsive divider with "OR" in the middle of it */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: theme.spacing(0, 4, 4, 4),
            width: '100%',
            padding: theme.spacing(0, 4),
          }}
        >
          <Box sx={{ border: '0.05em solid #000', width: '100%' }} />
          <Box sx={{ px: 2 }}>OR</Box>
          <Box sx={{ border: '0.05em solid #000', width: '100%' }} />
        </Box>

        {/* ----------- OR ------------- */}

        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            mt: 1,
            margin: theme.spacing(0, 4),
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                onChange={handleValueChange}
                onBlur={onValidateField}
                error={isFieldValid('firstName')}
                helperText={formErrorMessages['firstName']}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
                onChange={handleValueChange}
                onBlur={onValidateField}
                error={isFieldValid('lastName')}
                helperText={formErrorMessages['lastName']}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={handleValueChange}
                onBlur={onValidateField}
                error={isFieldValid('email')}
                helperText={formErrorMessages['email']}
              />
            </Grid>
            {/*}
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                onChange={handleValueChange}
                onBlur={onValidateField}
                error={isFieldValid('password')}
                helperText={formErrorMessages['password']}
              />
            </Grid>
            {*/}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="allowExtraEmails"
                    color="primary"
                    onChange={handleCheckboxChange}
                    checked={formValues['allowExtraEmails']}
                  />
                }
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid>
          </Grid>
          {generalFormErrorMessage && (
            <Typography variant="body2" color="error">
              {generalFormErrorMessage}
            </Typography>
          )}
          <Button disabled={!isFormValid()} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/signin" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  )
}

export default Signup
