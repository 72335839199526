import React from 'react'
import PropTypes from 'prop-types'
import logo from '../../assets/gospelgarden-link-image.jpeg'
import { Helmet, HelmetData } from 'react-helmet-async'

const buildBaseURL = () => {
  const protocol = window.location.protocol
  const domainName = window.location.hostname
  const port = window.location.port

  // If port is empty, omit it in the URL
  if (port) {
    return `${protocol}//${domainName}:${port}`
  }
  return `${protocol}//${domainName}`
}

const PageMetadata = (props) => {
  const { helmetData, pageTitle, pageDescription } = props

  // get current domain name
  const baseUrl = buildBaseURL()

  // get logo with domain name and protocol
  const fullLogoUrl = baseUrl + logo

  const descriptionComponent = <meta name="description" content={pageDescription} />

  const ogTypeComponent = <meta property="og:type" content="website" />
  const ogTitleComponent = <meta property="og:title" content={pageTitle} />
  const ogDescriptionComponent = <meta property="og:description" content={pageDescription} />
  const ogImageComponent = <meta property="og:image" content={fullLogoUrl} />

  const twitterCardComponent = <meta name="twitter:card" content="summary_large_image" />
  const twitterSiteComponent = <meta name="twitter:site" content="@gospelgarden7" />
  const twitterTitleComponent = <meta name="twitter:title" content={pageTitle} />
  const twitterDescriptionComponent = <meta name="twitter:description" content={pageDescription} />
  const twitterImageComponent = <meta name="twitter:image" content={fullLogoUrl} />

  return (
    <Helmet prioritizeSeoTags helmetData={helmetData}>
      {pageTitle ? <title>{pageTitle}</title> : null}
      {pageDescription ? descriptionComponent : null}

      {ogTypeComponent}
      {pageTitle ? ogTitleComponent : null}
      {pageDescription ? ogDescriptionComponent : null}
      {logo ? ogImageComponent : null}

      {twitterCardComponent}
      {twitterSiteComponent}
      {pageTitle ? twitterTitleComponent : null}
      {pageDescription ? twitterDescriptionComponent : null}
      {logo ? twitterImageComponent : null}
    </Helmet>
  )
}

PageMetadata.propTypes = {
  helmetData: PropTypes.object,
  pageTitle: PropTypes.string,
  pageDescription: PropTypes.string,
}

PageMetadata.defaultProps = {
  helmetData: new HelmetData({}),
  pageTitle: process.env.REACT_APP_SITE_NAME,
  pageDescription: 'Gospel Garden is a place to learn about the Bible and Christianity',
}

export default PageMetadata
