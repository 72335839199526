import React from 'react'
import PropTypes from 'prop-types'

import { get } from 'lodash-es'
import GoogleButton from 'react-google-button'
import { styled } from '@mui/material/styles'
import useTheme from '@mui/material/styles/useTheme'

import { gql, useQuery, requestWrapper } from '../../../lib/graphql-helpers'

const GET_GOOGLE_AUTHORIZATION_URL = gql`
  query getGoogleAuthorizationUrl {
    getGoogleAuthorizationUrl {
      url
    }
  }
`

const GoogleButtonWrapper = styled('div', { name: 'GoogleButtonWrapper' })(({ theme }) => ({
  width: '240px',
  margin: theme.spacing(0, 'auto'),
}))

const GoogleAuthButton = ({ buttonLabel }) => {
  const theme = useTheme()

  const { isSuccess: isGoogleAuthUrlSuccess, data: googleAuthUrlData } = useQuery({
    queryKey: ['getGoogleAuthorizationUrl'],
    queryFn: requestWrapper(GET_GOOGLE_AUTHORIZATION_URL),
  })

  const goGoogleLogin = (evt) => {
    if (evt) {
      evt.preventDefault()
    }
    const authorizeURL = get(googleAuthUrlData, 'getGoogleAuthorizationUrl.url')
    if (typeof window !== 'undefined') {
      window.location.assign(authorizeURL)
    }
  }

  return (
    <>
      {isGoogleAuthUrlSuccess && (
        <GoogleButtonWrapper theme={theme}>
          <GoogleButton label={buttonLabel} onClick={goGoogleLogin} />
        </GoogleButtonWrapper>
      )}
    </>
  )
}

GoogleAuthButton.propTypes = {
  buttonLabel: PropTypes.string,
}

GoogleAuthButton.defaultProps = {
  buttonLabel: 'Sign in with Google',
}

export default GoogleAuthButton
