import React, { useState, useEffect } from 'react'

import { Outlet, useNavigate } from 'react-router-dom'
import { useCurrentUser } from '../../providers/CurrentUserProvider'

const PrivateRoute = () => {
  const [authenticated, setAuthenticated] = useState(null)
  const { currentUser, isCurrentUserLoaded, refetchCurrentUser } = useCurrentUser()

  const navigate = useNavigate()

  useEffect(() => {
    refetchCurrentUser()
  }, [refetchCurrentUser])

  useEffect(() => {
    if (isCurrentUserLoaded) {
      setAuthenticated(!!currentUser) // double bang returns the true/false value of a non-boolean variable
    }
  }, [currentUser, isCurrentUserLoaded])

  useEffect(() => {
    if (authenticated === false && isCurrentUserLoaded === true) {
      return navigate('/signin')
    }
  }, [authenticated])

  return <Outlet />
}

export default PrivateRoute
