import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import useTheme from '@mui/material/styles/useTheme'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
// import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import { useNavigate, createSearchParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import { Tabs } from '@mui/material'
import { Tab } from '@mui/material'

const a11yProps = (index) => {
  return {
    id: `parts-tab-${index}`,
    'aria-controls': `parts-tabpanel-${index}`,
  }
}

const SearchBar = (props) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [query, setQuery] = useState(props.query || '')
  const [parts, setParts] = useState(props.parts || 'all')
  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState(true)

  useEffect(() => {
    if (query === null || query === '') {
      setIsSearchButtonDisabled(true)
    } else {
      setIsSearchButtonDisabled(false)
    }
  }, [query])

  const doSearch = useCallback(() => {
    // go to /search with react-router-dom and add query as param

    const newSearchParams = {
      q: query,
    }
    if (parts !== 'all') {
      newSearchParams.parts = parts
    }

    navigate({
      // pathname: 'search',
      search: createSearchParams(newSearchParams).toString(),
    })
  }, [query, parts])

  const onSearchInputChange = (event) => {
    const qry = event.target.value
    setQuery(qry)
  }

  const onSearchButtonPressed = async () => {
    doSearch()
  }

  // make input work on key press enter
  const handleSubmitOnKeyDown = (event) => {
    if (event.key === 'Enter') {
      doSearch()
    }
  }

  const tabs = [
    { label: 'All', value: 'all' },
    { label: 'Old Testament', value: 'ot' },
    { label: 'New Testament', value: 'nt' },
  ]

  const [tabIndexValue, setTabIndexValue] = useState(0)

  useEffect(() => {
    // find value in tabs and get index
    const tabIndex = tabs.findIndex((tab) => tab.value === parts)
    setTabIndexValue(tabIndex)
  }, [parts])

  const handleTabChange = (event, newIndexValue) => {
    // console.log('handleTabChange', {event, newIndexValue})
    const newParts = tabs[newIndexValue].value
    setParts(newParts)
  }

  useEffect(() => {
    doSearch()
  }, [parts])

  return (
    <>
      <div>
        <TextField
          type="text"
          placeholder="Who is Jesus?"
          value={query}
          onChange={onSearchInputChange}
          onKeyDown={handleSubmitOnKeyDown}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            width: '75%',
          }}
        />
        <Button
          color="primary"
          variant="contained"
          aria-label="search"
          disabled={isSearchButtonDisabled}
          onClick={() => onSearchButtonPressed()}
          sx={{
            margin: {
              xs: theme.spacing(1, 0, 0, 0.5),
              sm: theme.spacing(1, 0, 0, 1),
              md: theme.spacing(1, 0, 0, 2),
            },
          }}
        >
          <SearchIcon />
        </Button>
      </div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndexValue} onChange={handleTabChange} aria-label="basic tabs example">
          {tabs.map((tab, index) => (
            <Tab key={`tabindex-${index}`} label={tab.label} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>
    </>
  )
}

SearchBar.propTypes = {
  query: PropTypes.string,
  parts: PropTypes.string,
}

export default SearchBar
