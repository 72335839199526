import React from 'react'
import { styled } from '@mui/material/styles'
import useTheme from '@mui/material/styles/useTheme'
import { Helmet } from 'react-helmet-async'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Link from '@mui/material/Link'

const TermsContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(4),
}))

const StyledSection = styled('section')(() => ({
  marginBottom: ({ theme }) => theme.spacing(3),
}))

const StyledSectionTitle = styled('h1')(() => ({
  marginBottom: ({ theme }) => theme.spacing(3),
}))

const StyledParagraph = styled('p')(() => ({
  marginBottom: ({ theme }) => theme.spacing(3),
}))

const siteName = 'Gospel Garden'

const Terms = () => {
  const theme = useTheme()
  const backgroundColorStyle = `body { background-color: ${theme.palette.background.default} }`
  return (
    <Container
      component="main"
      sx={{
        margin: theme.spacing(2, 0, 2, 0),
      }}
    >
      <Helmet>
        <title>Terms of Service</title>
        <style>{backgroundColorStyle}</style>
      </Helmet>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: theme.spacing(2),
        }}
      >
        <TermsContainer>
          <StyledSection>
            <StyledSectionTitle variant="h5">Terms of Service</StyledSectionTitle>
            <StyledParagraph>
              Welcome to {siteName}. These Terms of Service govern your use of our website; by using our website, you accept these Terms of
              Service in full. If you disagree with these Terms of Service or any part of these Terms of Service, you must not use our
              website.
            </StyledParagraph>
          </StyledSection>

          <StyledSection>
            <StyledSectionTitle variant="h6">Section 1: Introduction</StyledSectionTitle>
            <StyledParagraph>
              By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use our website if you
              do not agree to take all of the terms and conditions stated on this page.
            </StyledParagraph>
          </StyledSection>

          <StyledSection>
            <StyledSectionTitle variant="h6">Section 2: User Responsibilities</StyledSectionTitle>
            <StyledParagraph>
              In using this website, you agree to provide accurate, current, and complete information where required and to maintain the
              confidentiality of your account and password. You are responsible for all activities that occur under your account or
              password, including any breach of security or unauthorized use.
            </StyledParagraph>
          </StyledSection>

          <StyledSection>
            <StyledSectionTitle variant="h6">Section 3: Prohibited Activities</StyledSectionTitle>
            <StyledParagraph>
              You agree not to use the website for any unlawful purpose or any purpose prohibited under this clause. You agree not to use
              the website in any way that could damage the website, the services, or the general business of {siteName}. Prohibited
              activities include, but are not limited to:
            </StyledParagraph>

            <StyledParagraph>
              <ul>
                <li>
                  Engaging in any data mining, data harvesting, data extracting, or any other similar activity in relation to our website.
                </li>
                <li>Using this website to engage in any advertising or marketing, except with our express written consent.</li>
                <li>
                  Uploading or transmitting viruses or any other type of malicious code that will or may be used in any way that will affect
                  the functionality or operation of the website.
                </li>
                <li>
                  Attempting to gain unauthorized access to any portion or feature of the website, or any other systems or networks
                  connected to the website.
                </li>
              </ul>
            </StyledParagraph>
          </StyledSection>

          <StyledSection>
            <StyledSectionTitle variant="h6">Section 4: Intellectual Property Rights</StyledSectionTitle>
            <StyledParagraph>
              Unless otherwise stated, {siteName} and/or its licensors own the intellectual property rights for all material on this
              website. All intellectual property rights are reserved. You may access this from {siteName} for your own personal use
              subjected to restrictions set in these terms and conditions. You must not:
            </StyledParagraph>

            <StyledParagraph>
              <ul>
                <li>Republish material from our website.</li>
                <li>Sell, rent, or sub-license material from our website.</li>
                <li>Reproduce, duplicate, or copy material from our website.</li>
                <li>Redistribute content from our website, unless content is specifically made for redistribution.</li>
              </ul>
            </StyledParagraph>
          </StyledSection>

          <StyledSection>
            <StyledSectionTitle variant="h6">Section 5: Limitation of Liability</StyledSectionTitle>
            <StyledParagraph>
              In no event shall {siteName}, nor any of its officers, directors, and employees, be liable to you for anything arising out of
              or in any way connected with your use of this website, whether such liability is under contract, tort, or otherwise, and{' '}
              {siteName}, including its officers, directors, and employees shall not be liable for any indirect, consequential, or special
              liability arising out of or in any way related to your use of this website.
            </StyledParagraph>
          </StyledSection>

          <StyledSection>
            <StyledSectionTitle variant="h6">Section 6: Termination</StyledSectionTitle>
            <StyledParagraph>
              We may terminate or suspend access to our website immediately, without prior notice or liability, for any reason whatsoever,
              including without limitation if you breach the Terms of Service. Upon termination, your right to use the website will
              immediately cease.
            </StyledParagraph>
          </StyledSection>

          <StyledSection>
            <StyledSectionTitle variant="h6">Section 7: Governing Law</StyledSectionTitle>
            <StyledParagraph>
              These Terms of Service and any separate agreements whereby we provide you services shall be governed by and construed in
              accordance with the laws of the jurisdiction in which {siteName} operates, without regard to its conflict of law provisions.
            </StyledParagraph>
          </StyledSection>

          <StyledSection>
            <StyledSectionTitle variant="h6">Section 8: Changes to Terms of Service</StyledSectionTitle>
            <StyledParagraph>
              {siteName} reserves the right to revise these Terms of Service at any time as it sees fit, and by using this website, you are
              expected to review these Terms of Service on a regular basis to ensure you understand all terms and conditions governing use
              of this website.
            </StyledParagraph>
          </StyledSection>

          <StyledSection>
            <StyledSectionTitle variant="h6">Section 9: Contact Information</StyledSectionTitle>
            <StyledParagraph>
              If you have any questions about these Terms of Service, please contact us at our <Link href="/contact">contact form</Link>.
            </StyledParagraph>

            <StyledParagraph>
              By using this website, you acknowledge that you have read these terms of service and agree to be bound by them.
            </StyledParagraph>
          </StyledSection>
        </TermsContainer>
      </Paper>
    </Container>
  )
}

export default Terms
