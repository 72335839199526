import { createTheme } from '@mui/material/styles'
// import { blue, grey, red, green } from '@mui/material/colors'
// import '@fontsource/roboto'
import '@fontsource/montserrat'

export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440,
      xl: 1920,
    },
  },
  palette: {
    // gospelgarden.co scheme
    // Primary Green: #4C9070 (for buttons, icons, and accents)
    // Secondary Green: #A6D4B5 (for background highlights and menu hover states)
    // Dark Green: #235B4E (for text and header backgrounds)
    // White: #FFFFFF (for content backgrounds and text on dark green)
    // Light Gray: #F2F2F2 (for footer backgrounds and section dividers)
    primary: {
      main: '#4C9070',
      light: '#A6D4B5',
      dark: '#235B4E',
    },
    secondary: {
      main: '#F2F2F2',
    },
    background: {
      default: '#F2F2F2',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#235B4E',
      secondary: '#4C9070',
    },
    action: {
      hover: '#A6D4B5',
    },

    // default: grey[300],
    // primary: blue,
    // secondary: red,
    // background: grey[100],
    // positive: green[500],
  },
  typography: {
    // fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    typography: {
      h1: {
        fontFamily: 'Montserrat, Arial, sans-serif',
        fontWeight: 700,
      },
      h2: {
        fontFamily: 'Montserrat, Arial, sans-serif',
        fontWeight: 700,
      },
      h3: {
        fontFamily: 'Montserrat, Arial, sans-serif',
        fontWeight: 700,
      },
      body1: {
        fontFamily: 'Lato, Arial, sans-serif',
      },
      body2: {
        fontFamily: 'Lato, Arial, sans-serif',
      },
      button: {
        fontFamily: 'Montserrat, Arial, sans-serif',
        fontWeight: 700,
      },
    },
  },
})
