import React from 'react'
import { Outlet } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import useTheme from '@mui/material/styles/useTheme'
import Container from '@mui/material/Container'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

const PrivatePageDefault = () => {
  const theme = useTheme()
  const backgroundColorStyle = `body { background-color: ${theme.palette.background.default} }`

  return (
    <Container>
      <Helmet>
        <style>{backgroundColorStyle}</style>
      </Helmet>
      <Header />
      <Outlet />
      <Footer />
    </Container>
  )
}

export default PrivatePageDefault
