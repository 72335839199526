import React from 'react'
import useTheme from '@mui/material/styles/useTheme'
import { Helmet } from 'react-helmet-async'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

const StatementOfFaith = () => {
  const theme = useTheme()
  const backgroundColorStyle = `body { background-color: ${theme.palette.background.default} }`

  return (
    <Container
      component="main"
      sx={{
        margin: theme.spacing(2, 0, 2, 0),
      }}
    >
      <Helmet>
        <style>{backgroundColorStyle}</style>
      </Helmet>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: theme.spacing(2),
        }}
      >
        <h1>Statement of Faith</h1>
        <Typography variant="p" padding={2}>
          We believe in the verbal inspiration of the Bible, both the Old and New Testaments (2 Timothy 3:16-17; 2 Peter 1:20-21); the
          Trinity of the Godhead (Matthew 28:19; 2 Corinthians 13:14); the deity of the Lord Jesus Christ (John 1:1, 14; Colossians 2:9);
          His virgin birth (Isaiah 7:14; Matthew 1:23); His sinless life (Hebrews 4:15; 1 Peter 2:22); His vicarious and atoning death
          through His shed blood (Romans 5:8-9; 1 Peter 2:24); His bodily resurrection (1 Corinthians 15:3-4; Luke 24:6-7); His ascension to
          the right hand of the Father (Acts 1:9; Hebrews 1:3); and His personal, bodily return in power and glory (Acts 1:11; Revelation
          19:11-16).
        </Typography>

        <Typography variant="p" padding={2}>
          We believe that salvation is by grace through faith, not by works (Ephesians 2:8-9; Titus 3:5), and is available to all who repent
          of their sins and trust in Jesus Christ as their Lord and Savior (Acts 2:38; Romans 10:9-10). We believe in the necessity of
          regeneration by the Holy Spirit (John 3:5-6; Titus 3:5), who indwells the believer (1 Corinthians 6:19-20) and empowers them for
          godly living and service (Galatians 5:22-23; Acts 1:8).
        </Typography>

        <Typography variant="p" padding={2}>
          We believe in the resurrection of both the saved and the lost; the saved to eternal life (John 5:28-29; 1 Thessalonians 4:16-17)
          and the lost to eternal separation from God (Revelation 20:11-15; Matthew 25:46). We believe in the spiritual unity of believers
          in our Lord Jesus Christ (Ephesians 4:3-6), with equality across racial, gender, and socio-economic differences (Galatians 3:28;
          Colossians 3:11).
        </Typography>

        <Typography variant="p" padding={2}>
          We believe in the ordinances of the church, specifically baptism by immersion for believers (Matthew 28:19; Romans 6:4),
          symbolizing the believer&apos;s faith in a crucified, buried, and risen Savior, and the Lord&apos;s Supper as a memorial of
          Christ&apos;s death and anticipated return (1 Corinthians 11:23-26).
        </Typography>

        <Typography variant="p" padding={2}>
          We believe in the autonomy of the local church (Acts 14:23; Titus 1:5), free of any external authority or control, with the right
          to self-government and the freedom from interference by any hierarchy of individuals or organizations (Matthew 18:15-17).
        </Typography>

        <Typography variant="p" padding={2}>
          We believe in religious liberty; that every individual has the right to worship God according to the dictates of their own
          conscience (Romans 14:5-12; Galatians 5:1).
        </Typography>

        <Typography variant="p" padding={2}>
          We believe that the church is the body of Christ (1 Corinthians 12:12-27; Ephesians 1:22-23), tasked with the mission of making
          disciples of all nations, baptizing them, and teaching them to observe all that Jesus commanded (Matthew 28:18-20).
        </Typography>
      </Paper>
    </Container>
  )
}

export default StatementOfFaith
