import React from 'react'
import { useParams } from 'react-router-dom'

const QuestionVerseDetail = () => {
  // get the questionSlug and verseSlug from route params
  const routeParams = useParams()
  const { questionSlug, verseSlug } = routeParams
  return (
    <div>
      <h1>QuestionVerseDetail</h1>
      <div>
        <p>Question: {questionSlug}</p>
        <p>Verse: {verseSlug}</p>
      </div>
    </div>
  )
}

export default QuestionVerseDetail
