import React from 'react'
import { styled } from '@mui/material/styles'
import useTheme from '@mui/material/styles/useTheme'
import { Helmet } from 'react-helmet-async'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

const PolicyContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(4),
}))

const Title = styled('h1')(() => ({
  marginBottom: ({ theme }) => theme.spacing(2),
}))

const Subtitle = styled('h2')(() => ({
  marginTop: ({ theme }) => theme.spacing(2),
  marginBottom: ({ theme }) => theme.spacing(1),
}))

const Section = styled('section')(() => ({
  marginBottom: ({ theme }) => theme.spacing(3),
}))

const siteName = 'Gospel Garden'

const PrivacyPolicy = () => {
  const theme = useTheme()
  const backgroundColorStyle = `body { background-color: ${theme.palette.background.default} }`

  return (
    <Container
      component="main"
      sx={{
        margin: theme.spacing(2, 0, 2, 0),
      }}
    >
      <Helmet>
        <title>Privacy Policy</title>
        <style>{backgroundColorStyle}</style>
      </Helmet>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: theme.spacing(2),
        }}
      >
        <PolicyContainer>
          <Title variant="h4" component="h1">
            Privacy Policy for {siteName}
          </Title>
          <Typography variant="subtitle1" component="h2">
            Effective Date: May 1, 2023
          </Typography>

          <Section>
            <Typography variant="body1">
              At {siteName}, we respect your privacy and are committed to protecting your personal information. This Privacy Policy explains
              how we collect, use, disclose, and safeguard your information when you use our social media site. By accessing or using our
              services, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy.
            </Typography>
          </Section>

          <Section>
            <Subtitle variant="h6" component="h3">
              1. Information We Collect
            </Subtitle>
            <Subtitle variant="subtitle1" component="h4">
              1.1 Personal Information
            </Subtitle>
            <Typography variant="body1">
              When you create an account on {siteName}, we may collect certain personally identifiable information, including your name,
              email address, username, and profile picture. If you choose to log in using Google Login or Spotify Login, we may collect
              additional information as allowed by these authentication programs, such as your Google or Spotify account ID, name, email
              address, profile picture, and any other information you have chosen to make public on those platforms.
            </Typography>

            <Subtitle variant="subtitle1" component="h4">
              1.2 Usage Information
            </Subtitle>
            <Typography variant="body1">
              We may automatically collect information about your interactions with our site, such as the pages you visit, the features you
              use, the content you post, and other actions you take on {siteName}.
            </Typography>
          </Section>

          <Section>
            <Subtitle variant="h6" component="h3">
              2. Use of Information
            </Subtitle>
            <Subtitle variant="subtitle1" component="h4">
              2.1 Provide and Improve Services
            </Subtitle>
            <Typography variant="body1">
              We use the collected information to provide, maintain, and improve our services, including user authentication, personalized
              content, and recommendations. We may also use the information to respond to your inquiries, troubleshoot issues, and
              communicate with you about service-related matters.
            </Typography>

            <Subtitle variant="subtitle1" component="h4">
              2.2 Personalization and Analytics
            </Subtitle>
            <Typography variant="body1">
              We may analyze the information to personalize your experience on {siteName}, including showing you relevant content and
              advertisements. We may also use aggregated and anonymized data for analytical purposes to understand and enhance the
              performance of our services.
            </Typography>
          </Section>

          <Section>
            <Subtitle variant="h6" component="h3">
              3. Disclosure of Information
            </Subtitle>
            <Subtitle variant="subtitle1" component="h4">
              3.1 Third-Party Service Providers
            </Subtitle>
            <Typography variant="body1">
              We may engage third-party companies and individuals to perform services on our behalf, such as hosting, data analysis,
              customer support, and marketing assistance. These third parties may have access to your information only to perform these
              tasks on our behalf and are obligated not to disclose or use it for any other purpose.
            </Typography>

            <Subtitle variant="subtitle1" component="h4">
              3.2 Legal Requirements
            </Subtitle>
            <Typography variant="body1">
              We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a
              court or government agency).
            </Typography>

            <Subtitle variant="subtitle1" component="h4">
              3.3 Business Transfers
            </Subtitle>
            <Typography variant="body1">
              In the event of a merger, acquisition, or sale of all or a portion of our assets, we may transfer your information to the
              acquiring entity or successor.
            </Typography>
          </Section>

          <Section>
            <Subtitle variant="h6" component="h3">
              4. Data Security
            </Subtitle>
            <Typography variant="body1">
              We implement reasonable security measures to protect your information from unauthorized access, disclosure, alteration, or
              destruction. However, please note that no method of transmission over the internet or electronic storage is completely secure,
              and we cannot guarantee absolute security of your information.
            </Typography>
          </Section>

          <Section>
            <Subtitle variant="h6" component="h3">
              5. Your Choices
            </Subtitle>
            <Typography variant="body1">
              You may access and update certain personal information within your {siteName} account settings. You can choose to connect or
              disconnect your Google or Spotify account through the settings in your {siteName} account.
            </Typography>
          </Section>

          <Section>
            <Subtitle variant="h6" component="h3">
              6. Children&apos;s Privacy
            </Subtitle>
            <Typography variant="body1">
              {siteName} is not intended for use by individuals under the age of 13. If we become aware that we have collected personal
              information from a child under 13 without parental consent, we will take steps to remove the information and terminate the
              child&apos;s account.
            </Typography>
          </Section>

          <Section>
            <Subtitle variant="h6" component="h3">
              7. Changes to this Privacy Policy
            </Subtitle>
            <Typography variant="body1">
              We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on
              this page.
            </Typography>
          </Section>

          <Section>
            <Subtitle variant="h6" component="h3">
              8. Contact Us
            </Subtitle>
            <Typography variant="body1">
              If you have any questions or concerns about this Privacy Policy, please contact us at our{' '}
              <Link href="/contact">contact form</Link>.
            </Typography>
          </Section>

          <Typography variant="body1">
            By using {siteName}, you acknowledge that you have read and understood this Privacy Policy and consent to the collection, use,
            and disclosure of your information in accordance with this policy.
          </Typography>
        </PolicyContainer>
      </Paper>
    </Container>
  )
}

export default PrivacyPolicy
