import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Drawer from '@mui/material/Drawer'
import MenuIcon from '@mui/icons-material/Menu'

import GospelGardenLogo from '../../assets/gospelgarden-logo-horizontal.png'
import { useCurrentUser } from '../../providers/CurrentUserProvider'
import { useEffect } from 'react'

const logoStyle = {
  width: '140px',
  height: 'auto',
  cursor: 'pointer',
}

const AppAppBar = () => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const { currentUser, isCurrentUserLoaded } = useCurrentUser()

  const [isAuthenticated, setIsAuthenticated] = useState(null)

  useEffect(() => {
    if (isCurrentUserLoaded) {
      setIsAuthenticated(!!currentUser)
    }
  }, [currentUser, isCurrentUserLoaded])

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen)
  }

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId)
    const offset = 128
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset
      sectionElement.scrollIntoView({ behavior: 'smooth' })
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      })
      setOpen(false)
    }
  }

  const navigateToPage = (url) => {
    navigate(url)
  }

  const gotoHomepage = () => {
    navigate('/')
  }

  return (
    <div>
      <AppBar
        position="static"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '.25em',
              // bgcolor: theme.palette.primary.dark,
              bgcolor: 'background.paper',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
                '0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)',
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-18px',
                px: 0,
              }}
            >
              <img onClick={gotoHomepage} src={GospelGardenLogo} style={logoStyle} alt="gospel garden logo" />
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                {isAuthenticated ? (
                  <>
                    <MenuItem onClick={() => navigateToPage('/dashboard')} sx={{ py: '6px', px: '12px' }}>
                      <Typography variant="body2" color="text.primary">
                        Dashboard
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => navigateToPage('/')} sx={{ py: '6px', px: '12px' }}>
                      <Typography variant="body2" color="text.primary">
                        Bible Semantic Search
                      </Typography>
                    </MenuItem>
                    {/*}
                    <MenuItem onClick={() => navigateToPage('/faith')} sx={{ py: '6px', px: '12px' }}>
                      <Typography variant="body2" color="text.primary">
                        Statement of Faith
                      </Typography>
                    </MenuItem>
                    {*/}
                    <MenuItem onClick={() => navigateToPage('/contact')} sx={{ py: '6px', px: '12px' }}>
                      <Typography variant="body2" color="text.primary">
                        Contact Us
                      </Typography>
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem onClick={() => navigateToPage('/')} sx={{ py: '6px', px: '12px' }}>
                      <Typography variant="body2" color="text.primary">
                        Bible Semantic Search
                      </Typography>
                    </MenuItem>
                    {/*}
                    <MenuItem onClick={() => navigateToPage('/faith')} sx={{ py: '6px', px: '12px' }}>
                      <Typography variant="body2" color="text.primary">
                        Statement of Faith
                      </Typography>
                    </MenuItem>
                    {*/}
                    <MenuItem onClick={() => navigateToPage('/contact')} sx={{ py: '6px', px: '12px' }}>
                      <Typography variant="body2" color="text.primary">
                        Contact Us
                      </Typography>
                    </MenuItem>
                  </>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              {isAuthenticated ? (
                <>
                  <Button color="primary" variant="text" size="small" component="a" href="/signout">
                    Sign out
                  </Button>
                </>
              ) : (
                <>
                  <Button color="primary" variant="text" size="small" component="a" href="/signin">
                    Sign in
                  </Button>
                  <Button color="primary" variant="contained" size="small" component="a" href="/signup">
                    Sign up
                  </Button>
                </>
              )}
            </Box>
            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button variant="text" color="primary" aria-label="menu" onClick={toggleDrawer(true)} sx={{ minWidth: '30px', p: '4px' }}>
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: '60dvw',
                    p: 2,
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'end',
                      flexGrow: 1,
                    }}
                  />
                  {/*}
                  <MenuItem onClick={() => scrollToSection('features')}>Features</MenuItem>
                  <MenuItem onClick={() => scrollToSection('testimonials')}>Testimonials</MenuItem>
                  <MenuItem onClick={() => scrollToSection('highlights')}>Highlights</MenuItem>
                  <MenuItem onClick={() => scrollToSection('pricing')}>Pricing</MenuItem>
                  <MenuItem onClick={() => scrollToSection('faq')}>FAQ</MenuItem>
                  <Divider />
                  {*/}
                  {isAuthenticated ? (
                    <>
                      <MenuItem onClick={() => navigateToPage('/dashboard')}>Dashboard</MenuItem>
                      <MenuItem onClick={() => navigateToPage('/')}>Bible Search</MenuItem>
                      {/*<MenuItem onClick={() => navigateToPage('/faith')}>Statement of Faith</MenuItem> */}
                      <MenuItem onClick={() => navigateToPage('/contact')}>Contact Us</MenuItem>
                    </>
                  ) : (
                    <>
                      <MenuItem onClick={() => navigateToPage('/')}>Bible Search</MenuItem>
                      {/* <MenuItem onClick={() => navigateToPage('/faith')}>Statement of Faith</MenuItem> */}
                      <MenuItem onClick={() => navigateToPage('/contact')}>Contact Us</MenuItem>
                    </>
                  )}

                  <Divider />
                  {isAuthenticated ? (
                    <>
                      <MenuItem>
                        <Button color="primary" variant="outlined" component="a" href="/signout" sx={{ width: '100%' }}>
                          Sign out
                        </Button>
                      </MenuItem>
                    </>
                  ) : (
                    <>
                      <MenuItem>
                        <Button color="primary" variant="contained" component="a" href="/signup" sx={{ width: '100%' }}>
                          Sign up
                        </Button>
                      </MenuItem>
                      <MenuItem>
                        <Button color="primary" variant="outlined" component="a" href="/signin" sx={{ width: '100%' }}>
                          Sign in
                        </Button>
                      </MenuItem>
                    </>
                  )}
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  )
}

export default AppAppBar
