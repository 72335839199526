import React from 'react'

import { styled } from '@mui/material/styles'

import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import useTheme from '@mui/material/styles/useTheme'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
// import FormControlLabel from '@mui/material/FormControlLabel'
// import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import { get } from 'lodash-es'
import { Helmet } from 'react-helmet-async'

import logoImage from '../../../assets/gospelgarden-logo-horizontal.png'
import { gql, useQuery, requestWrapper, useMutation } from '../../../lib/graphql-helpers'
import GoogleAuthButton from '../GoogleAuthButton'
import { request } from '../../../lib/graphql-helpers'
import { useImmer } from 'use-immer'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { createSearchParams } from 'react-router-dom'
import { getGraphQLErrorMessage } from '../../../lib/graphql-helpers'

const LOGIN_USER_BY_EMAIL = gql`
  mutation loginUserByEmail($email: String!, $source: String!) {
    loginUserByEmail(email: $email, source: $source) {
      userId
      token
    }
  }
`

const GET_FACEBOOK_AUTHORIZATION_URL = gql`
  query getFacebookAuthorizationUrl {
    getFacebookAuthorizationUrl {
      url
    }
  }
`

const LogoContainer = styled('div', { name: 'LogoContainer' })(({ theme }) => ({
  margin: theme.spacing(1, 1, 3, 1),
}))

const FacebookLoginButton = styled(Button)(({ theme }) => ({
  padding: 16,
  borderRadius: 4,
  background: '#3b5998',
  color: 'white',
  border: '0 transparent',
  textAlign: 'center',
  margin: 8,
  display: 'inline-block',
  '&:hover': {
    background: '#2f477a',
  },
}))

const Login = () => {
  const theme = useTheme()
  const backgroundColorStyle = `body { background-color: ${theme.palette.background.default} }`

  const navigate = useNavigate()

  const [formValues, setFormValues] = useImmer({
    email: '',
  })

  const [formErrorMessages, setFormErrorMessages] = useImmer({
    email: null,
  })

  const [generalFormErrorMessage, setGeneralFormErrorMessage] = useImmer(null)

  const { mutate, isLoading, isPending, isError, data, error, isSuccess } = useMutation({
    mutationFn: () => {
      const endpoint = `${process.env.REACT_APP_GRAPHQL_SERVER_ROOT_URL}/graphql`
      return request(endpoint, LOGIN_USER_BY_EMAIL, {
        email: formValues.email,
        source: 'signin-web',
      })
    },
  })

  useEffect(() => {
    if (isSuccess) {
      // console.log('User logged in successfully', data)
      const token = get(data, 'loginUserByEmail.token')
      navigate({
        pathname: '/otp',
        search: createSearchParams({
          t: token,
        }).toString(),
      })
    }
    if (isError) {
      const message = getGraphQLErrorMessage(error)
      if (message === 'USER_NOT_FOUND') {
        // TODO make this HTML and link to sign in page
        const errorMessage = 'It does not look like you signed up yet. Please get started by signing up.'
        setGeneralFormErrorMessage(errorMessage)
      }
    }
  }, [isSuccess, isError, isPending, error, data])

  const isFieldValid = (fieldName) => {
    return formErrorMessages[fieldName] !== null
  }

  const isFormValid = () => {
    const keys = Object.keys(formErrorMessages)
    // loop over all keys, check all null
    for (const key of keys) {
      if (formErrorMessages[key] !== null) {
        return false
      }
      if (formValues[key] === '') {
        return false
      }
    }
    return !generalFormErrorMessage
  }

  const handleValueChange = (evt) => {
    const { name, value } = evt.target
    setFormValues((draft) => {
      draft[name] = value
    })
    setGeneralFormErrorMessage(null)
  }

  const { isSuccess: isFacebookAuthUrlSuccess, data: facebookAuthUrlData } = useQuery({
    queryKey: ['getFacebookAuthorizationUrl'],
    queryFn: requestWrapper(GET_FACEBOOK_AUTHORIZATION_URL),
  })

  const goFacebookLogin = (evt) => {
    if (evt) {
      evt.preventDefault()
    }
    const authorizeURL = get(facebookAuthUrlData, 'getFacebookAuthorizationUrl.url')
    console.log('authorizeURL ', authorizeURL)
    if (typeof window !== 'undefined') {
      window.location.assign(authorizeURL)
    }
  }

  /*
  const goSpotifyLogin = (evt) => {
    if (evt) {
      evt.preventDefault()
    }
    const authorizeURL = get(spotifyAuthUrlData, 'getSpotifyAuthorizationUrl.url')
    if (typeof window !== 'undefined') {
      window.location.assign(authorizeURL)
    }
  }

   */
  const facebookAuthUrlLoading = false

  const onValidateField = (evt) => {
    const { name, value } = evt.target
    if (name === 'email') {
      if (value.length < 1) {
        setFormErrorMessages((draft) => {
          draft[name] = 'Email is required'
        })
      } else if (!value.includes('@')) {
        setFormErrorMessages((draft) => {
          draft[name] = 'Email is not valid'
        })
      } else {
        setFormErrorMessages((draft) => {
          draft[name] = null
        })
      }
    }
  }

  const handleSubmit = useCallback(
    (evt) => {
      evt.preventDefault()
      setGeneralFormErrorMessage(null)
      mutate()
    },
    [formValues]
  )

  return (
    <Container
      sx={{
        // alignItems: 'center',
        // justifyContent: 'center'
        margin: theme.spacing(2, 0, 2, 0),
      }}
    >
      <Helmet>
        <style>{backgroundColorStyle}</style>
      </Helmet>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: theme.spacing(2),
        }}
      >
        <LogoContainer>
          <img src={logoImage} width="300em" alt="logo" />
        </LogoContainer>

        <Typography component="h1" variant="h4" sx={{ marginBottom: theme.spacing(5) }}>
          Sign in
        </Typography>

        <Box
          sx={{
            mt: 1,
            margin: theme.spacing(0, 4, 4, 4),
          }}
        >
          <GoogleAuthButton buttonLabel="Sign in with Google" />
        </Box>

        {/*}
        {isFacebookAuthUrlSuccess && <FacebookLoginButton onClick={goFacebookLogin}>Login with Facebook</FacebookLoginButton>}
        {*/}

        {/* responsive divider with "OR" in the middle of it */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: theme.spacing(0, 4, 4, 4),
            width: '100%',
            padding: theme.spacing(0, 4),
          }}
        >
          <Box sx={{ border: '0.05em solid #000', width: '100%' }} />
          <Box sx={{ px: 2 }}>OR</Box>
          <Box sx={{ border: '0.05em solid #000', width: '100%' }} />
        </Box>

        {/* ----------- OR ------------- */}

        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            mt: 1,
            margin: theme.spacing(0, 4),
          }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            autoFocus
            name="email"
            autoComplete="email"
            onChange={(evt) => {
              handleValueChange(evt)
              onValidateField(evt)
            }}
            error={isFieldValid('email')}
            helperText={formErrorMessages['email']}
          />
          {generalFormErrorMessage && (
            <Typography variant="body2" color="error">
              {generalFormErrorMessage}
            </Typography>
          )}
          <Button disabled={!isFormValid()} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Sign In
          </Button>
          <Grid container>
            <Grid item>
              <Link href="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  )
}

export default Login
