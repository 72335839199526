import React from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'

import CssBaseline from '@mui/material/CssBaseline'

import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

import { HelmetProvider } from 'react-helmet-async'

// import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, from, ApolloLink } from '@apollo/client'
// import { onError } from '@apollo/client/link/error'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import reportWebVitals from './reportWebVitals'

// import { getJwtTokenFromCookie } from './lib/authentication/cookie'

import theme from './themes/default'

import router from './routes'
import CurrentUserProvider from './providers/CurrentUserProvider'

/*
const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_SERVER_ROOT_URL}/graphql`,
})
 */

/*
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    )
  }
  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`)
  }
})

 */

/*
const setAuthorizationLink = new ApolloLink((operation, forward) => {
  const headers = {}
  const token = getJwtTokenFromCookie()
  operation.setContext({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  })
  return forward(operation)
})

 */

const queryClient = new QueryClient()

/*
const client = new ApolloClient({
  name: 'bible-web-client',
  version: '1.0',
  cache: new InMemoryCache(),
  link: from([errorLink, setAuthorizationLink, httpLink]),
  defaultOptions: {
    query: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    },
  },
})
 */

const muiCache = createCache({
  key: 'mui',
  prepend: true,
})

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <CssBaseline />
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <CurrentUserProvider>
          <CacheProvider value={muiCache}>
            <ThemeProvider theme={theme}>
              <RouterProvider router={router} />
            </ThemeProvider>
          </CacheProvider>
        </CurrentUserProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
