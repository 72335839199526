import React, { useState, useEffect } from 'react'

import { gql, useQuery, requestWrapper, useMutation, mutationWrapper } from '../../lib/graphql-helpers'
import { useQueryParams } from '../../hooks/useQueryParams'
import { Skeleton } from '@mui/material'

// import { request, gql } from 'graphql-request'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
// import TextField from '@mui/material/TextField'
// import Button from '@mui/material/Button'
import SearchBar from './SearchBar'
import { useUuid } from '../../hooks/useGuid'
import PageMetadata from '../../components/PageMetadata'
import SocialShareCard from '../../components/SocialShareCard'

const QUERY_NEAREST_VERSES = gql`
  query queryNearestVerse($query: String!, $parts: String) {
    queryNearestVerses(query: $query, parts: $parts) {
      query_slug
      book
      book_name
      chapter
      combined_verses {
        texts {
          translation
          book
          book_name
          chapter
          verse
          text
          citation
        }
        citation
        slug
      }
    }
  }
`

const MAKE_SHORT_URL = gql`
  mutation makeShortUrl($url: String!) {
    makeShortUrl(url: $url) {
      shortUrl
    }
  }
`

const SearchResults = () => {
  const queryParams = useQueryParams()
  const theme = useTheme()

  // get the current url
  const currentUrl = window.location.href

  const uuid = useUuid()

  let defaultQuery = ''
  if (queryParams.get('q') !== null && queryParams.get('q') !== '') {
    defaultQuery = queryParams.get('q')
  }

  const [query, setQuery] = useState(defaultQuery)

  let defaultParts = 'all'
  if (queryParams.get('parts') !== null && queryParams.get('parts') !== '') {
    defaultParts = queryParams.get('parts')
  }
  const [parts, setParts] = useState(defaultParts)

  const { isLoading, isFetching, data, error, isSuccess, refetch } = useQuery({
    queryKey: ['searchResults'],
    queryFn: requestWrapper(
      QUERY_NEAREST_VERSES,
      {
        query: query,
        parts: parts,
      },
      {
        'x-client-uuid': uuid,
      }
    ),
  })

  const {
    mutate: makeShortUrl,
    data: shortUrlResult,
    isSuccess: isShortUrlCreated,
  } = useMutation({
    mutationFn: mutationWrapper(MAKE_SHORT_URL),
  })

  useEffect(() => {
    if (queryParams.get('q') !== null && queryParams.get('q') !== '') {
      setQuery(queryParams.get('q'))
    }
    if (queryParams.get('parts') !== null && queryParams.get('parts') !== '') {
      setParts(queryParams.get('parts'))
    }
  }, [queryParams, refetch])

  useEffect(() => {
    refetch()
  }, [query, parts, refetch])

  useEffect(() => {
    const main = async () => {
      if (currentUrl !== '' && currentUrl !== null && currentUrl !== undefined) {
        await makeShortUrl({
          url: currentUrl,
        })
      }
    }
    main()
  }, [makeShortUrl, currentUrl])

  // TODO handle error

  return (
    <Container
      sx={{
        padding: theme.spacing(4, 0, 2, 2),
      }}
    >
      <PageMetadata pageTitle={`${query} | Bible Search`} pageDescription={`Using Bible AI to find verses related to "${query}"`} />
      <SearchBar query={query} parts={parts} />
      {isShortUrlCreated && (
        <SocialShareCard
          shareUrl={shortUrlResult['makeShortUrl']['shortUrl']}
          shareTitle={`${query} | Bible Search`}
          shareDescription={`Search the Bible: ${query}`}
        />
      )}
      {isFetching && <Skeleton />}
      {!isFetching &&
        data &&
        data.queryNearestVerses &&
        data.queryNearestVerses.map((verse) => {
          const chapterBookKey = `${verse.chapter}-${verse.book_name}`
          return (
            <div key={chapterBookKey}>
              {verse.combined_verses.map((combinedVerse) => (
                <Paper
                  key={combinedVerse.citation}
                  sx={{
                    padding: 2,
                    margin: theme.spacing(0, 0, 2, 0),
                  }}
                >
                  <Typography
                    sx={{
                      margin: theme.spacing(0, 2, 1, 0),
                      textAlign: 'left',
                      fontWeight: 'bold',
                    }}
                  >
                    {combinedVerse.citation}
                  </Typography>
                  <>
                    {combinedVerse.texts.map((text) => {
                      const randomString = Math.random().toString(36).substring(7)
                      const verseKey = `${randomString}-${text.verse}`
                      return (
                        <Typography
                          key={verseKey}
                          sx={{
                            margin: theme.spacing(0, 2, 1, 0),
                            textAlign: 'left',
                          }}
                        >
                          <sup>{text.verse} </sup>
                          <span>{text.text}</span>
                        </Typography>
                      )
                    })}
                  </>
                </Paper>
              ))}
            </div>
          )
        })}
    </Container>
  )
}

export default SearchResults
