import React from 'react'
import PropTypes from 'prop-types'

// import SocialShareFallbackCard from './SocialShareFallback.js'
import WebShareCard from './WebShareCard.js'

const SocialShareCard = ({ shareUrl, shareTitle, shareDescription }) => {
  return <WebShareCard url={shareUrl} title={shareTitle} description={shareDescription} />

  // if (navigator.share) {
  //   return <WebShareCard url={shareUrl} title={shareTitle} description={shareDescription} />
  // }

  // return <SocialShareFallbackCard shareUrl={shareUrl} shareTitle={shareTitle} shareDescription={shareDescription} />
}

SocialShareCard.propTypes = {
  shareUrl: PropTypes.string.isRequired,
  shareTitle: PropTypes.string,
  shareDescription: PropTypes.string,
}

SocialShareCard.defaultProps = {
  shareUrl: window.location.href,
  shareTitle: null,
  shareDescription: null,
}

export default SocialShareCard
