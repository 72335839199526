import React, { useState, useEffect } from 'react'

import { styled } from '@mui/material/styles'
import { Container, Link, Typography } from '@mui/material'
import { get } from 'lodash-es'
import StatusIndicator from '../StatusIndicator'

import { gql, useQuery, requestWrapper } from '../../lib/graphql-helpers'

const GET_PING = gql`
  query getPing {
    ping {
      pong
      isAuthenticatedUser
    }
  }
`

const FooterContainer = styled('footer')(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  padding: theme.spacing(2),
  textAlign: 'center',
}))

const Footer = () => {
  const { data, isSuccess } = useQuery({
    queryKey: ['ping'],
    queryFn: requestWrapper(GET_PING, { query: 'love' }),
  })

  const [isDatabaseOnline, setIsDatabaseOnline] = useState(false)
  const [isAuthenticatedUser, setIsAuthenticatedUser] = useState(false)

  useEffect(() => {
    if (isSuccess) {
      const pong = get(data, 'ping.pong')
      if (pong) {
        setIsDatabaseOnline(true)
      }
      const isAuth = get(data, 'ping.isAuthenticatedUser')
      if (isAuth) {
        setIsAuthenticatedUser(true)
      }
    }
  }, [data, isSuccess])

  return (
    <FooterContainer>
      <Container>
        <Typography variant="body2" color="textSecondary">
          <Link href="/" color="inherit">
            Home
          </Link>
          {' | '}
          {isAuthenticatedUser ? (
            <Link href="/dashboard" color="inherit">
              Dashboard
            </Link>
          ) : (
            <Link href="/signin" color="inherit">
              Sign In
            </Link>
          )}
          {' | '}
          <Link href="/privacy" color="inherit">
            Privacy Policy
          </Link>
          {' | '}
          <Link href="/terms" color="inherit">
            Terms of Service
          </Link>
          {' | '}
          <StatusIndicator value={isDatabaseOnline} size=".5em" />
        </Typography>
      </Container>
    </FooterContainer>
  )
}

export default Footer
