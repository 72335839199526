import React from 'react'

import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

// import useTheme from '@mui/material/styles/useTheme'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '../../../lib/graphql-helpers'
import { requestWrapper } from '../../../lib/graphql-helpers'
import { gql } from '../../../lib/graphql-helpers'
import { get } from 'lodash-es'

const GET_USER_QUERY_HISTORY = gql`
  query getUserQueryHistory($first: Int!, $after: String) {
    getUserQueryHistory(input: { first: $first, afterCursor: $after }) {
      totalCount
      nodes {
        _id
        query
        queryType
        td
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

const QueryHistory = () => {
  // const theme = useTheme()
  const navigate = useNavigate()

  const questions = [
    'What does the Bible say about forgiveness?',
    'Can you find verses related to the concept of love in the New Testament?',
    'Where in the Bible does it discuss the creation of the world?',
    'How does the Bible address the issue of suffering?',
    'What are the Ten Commandments and where can they be found?',
    'What teachings does Jesus give about prayer?',
    'Where can I find the Beatitudes in the Bible?',
    'How is the concept of faith discussed in the letters of Paul?',
    'What are some prophetic books of the Old Testament?',
    'How does the Bible define the role of a prophet?',
  ]

  const { isLoading, isFetching, data, error, isSuccess, refetch } = useQuery({
    queryKey: ['getUserQueryHistory'],
    queryFn: requestWrapper(GET_USER_QUERY_HISTORY, {
      first: 10,
    }),
  })

  return (
    <Card sx={{ margin: 2 }}>
      <CardHeader title="Bible Question History" />
      <CardContent>
        <List>
          {get(data, 'getUserQueryHistory.nodes', []).map((item, index) => (
            <ListItemButton
              key={index}
              onClick={() => {
                return navigate(`/search?q=${encodeURI(item.query)}&source=history`)
              }}
            >
              <ListItemText>{item.query}</ListItemText>
            </ListItemButton>
          ))}
        </List>
      </CardContent>
    </Card>
  )
}

export default QueryHistory
