import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { useCurrentUser } from '../../providers/CurrentUserProvider'

import { gql } from '../../lib/graphql-helpers'
import useTheme from '@mui/material/styles/useTheme'
import { Helmet } from 'react-helmet-async'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import QueryHistory from './QueryHistory'

// const GET_PRIVATE_DASHBOARD = gql`
//   query getPrivateDashboard {
//   }
// `

const Dashboard = () => {
  const theme = useTheme()
  const backgroundColorStyle = `body { background-color: ${theme.palette.background.default} }`

  const { currentUser } = useCurrentUser()
  const navigate = useNavigate()

  const loading = false
  if (loading) {
    return (
      <main>
        <div>Loading...</div>
      </main>
    )
  }

  const data = 'hi'
  const error = false

  if (!data || error) {
    return (
      <main>
        <div>Error</div>
      </main>
    )
  }

  // console.log({ currentUser })
  return (
    <Container
      component="main"
      sx={{
        margin: theme.spacing(2, 0, 2, 0),
      }}
    >
      <Helmet>
        <title>Contact Us</title>
        <style>{backgroundColorStyle}</style>
      </Helmet>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          paddingBottom: theme.spacing(2),
        }}
      >
        <QueryHistory />
      </Paper>
    </Container>
  )
}

export default Dashboard
