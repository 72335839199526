import React, { useState, useEffect } from 'react'

import { useNavigate, createSearchParams } from 'react-router-dom'

import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'

const BibleSearchInput = () => {
  const navigate = useNavigate()

  const [query, setQuery] = useState('')
  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState(true)

  useEffect(() => {
    if (query === null || query === '') {
      setIsSearchButtonDisabled(true)
    } else {
      setIsSearchButtonDisabled(false)
    }
  }, [query])

  const doSearch = () => {
    // go to /search with react-router-dom and add query as param
    navigate({
      pathname: 'search',
      search: createSearchParams({
        q: query,
      }).toString(),
    })
  }

  const onSearchInputChange = (event) => {
    const qry = event.target.value
    setQuery(qry)
  }

  const onSearchButtonPressed = async () => {
    doSearch()
  }

  // make input work on key press enter
  const handleSubmitOnKeyDown = (event) => {
    if (event.key === 'Enter') {
      doSearch()
    }
  }

  return (
    <Container
      sx={{
        padding: 2,
        textAlign: 'center',
      }}
    >
      <Paper elevation={2} square={false}>
        <Typography fontWeight="bold" fontSize={36} paddingTop={2}>
          {/* Read the Bible */}
          AI Powered Bible Search
        </Typography>
        <Typography fontWeight="normal" fontSize={20} paddingBottom={4}>
          {/*Question your answers*/}
          Semantic Bible Search
        </Typography>
        <Box>
          <TextField
            type="text"
            placeholder="Who is Jesus Christ?"
            value={query}
            onChange={onSearchInputChange}
            onKeyDown={handleSubmitOnKeyDown}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              width: '75%',
            }}
          />
        </Box>
        <Button
          variant="contained"
          disabled={isSearchButtonDisabled}
          onClick={() => onSearchButtonPressed()}
          sx={{
            margin: 2,
          }}
        >
          Search
        </Button>
      </Paper>
    </Container>
  )
}

export default BibleSearchInput
